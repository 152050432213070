<template>
  <div class="mainpage">
    <div class="head">
      <div class="head-title">预习资料详情</div>
      <div class="retutnbt" @click="toback">返回</div>
    </div>
    <div class="line"></div>
    <div class="maincontain">
      <div class="main-contain">
        <!-- <div class="title">基础医学预习</div> -->
        <div class="contain">
          <el-table ref="singleTable" :data="tableData" highlight-current-row style="width: 100%" class="Table" :header-cell-style="{
              'font-size': '15px',
              color: '#666666',
              'font-weight': 'bold',
              background: '#F7F7F7',
            }" :row-style="{
              'font-size': '15px',
              color: '#222222',
              'font-weight': '400',
            }">
            <el-table-column label="课程" align="center">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.course_lib_name ? scope.row.course_lib_name : "-"
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="实验项目" align="center">
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.experiment_name ? scope.row.experiment_name : "-"
                  }}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="main-contain">
        <div class="title2">预习资料:</div>
        <div class="contain">
          <el-table ref="singleTable" :data="tableData2" highlight-current-row style="width: 100%" class="Table" :header-cell-style="{
              'font-size': '15px',
              color: '#666666',
              'font-weight': 'bold',
              background: '#F7F7F7',
            }" :row-style="{
              'font-size': '15px',
              color: '#222222',
              'font-weight': '400',
            }">
            <el-table-column label="文件名">
              <template slot-scope="scope">
                <div class="item-left">
                  <el-image class="elimage" :src="icon1" />
                  <div class="title">{{ scope.row.name }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="大小">
              <template slot-scope="scope">
                <div class="item-middle">
                  <div class="title">{{ scope.row.size }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <div class="item-right">
                  <div class="bt" @click="handelCheck(scope.row)">查看</div>
                  <div class="line2"></div>
                  <div class="bt" @click="handelDownload(scope.row)">下载</div>
                </div>
              </template>
            </el-table-column>
          </el-table>

        </div>
      </div>
    </div>

    <el-dialog title="文件预览" :visible.sync="dialogVisible" width="50%">
      <FileViews :url="url" :type="file_type" v-if="flag" />
    </el-dialog>
  </div>
</template>

<script>
import { getattachmentlist } from "@/api/attachment";
import Customdialog from "@/components/customdialog.vue";
import FileViews from "@/components/file-view";
export default {
  name: "mainpage",
  components: {
    Customdialog,
    FileViews,
  },
  data() {
    return {
      radio: "1",
      searchvalue: "",
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/coursedetails/文件类型-标准图-视频文件.png"),
      icon2: require("@/assets/coursedetails/矢量智能对象 (1).png"),
      tableData: [],
      tableData2: [],
      item: "",
      url: '',
      file_type: '',
      flag: false,
      dialogVisible: false
    };
  },
  computed: {},
  created() {
    this.item = this.$route.query;
    // console.log("JSON.parse(str)", JSON.parse(str))
    this.tableData.push({
      course_lib_name: this.item.course_lib_name,
      experiment_name: this.item.experiment_name,
    });

    this.getattachmentlist({ related_id: this.item.id, type: 1 ,per_page:100});
  },
  methods: {
    toback() {
      this.$router.push({
        path: "/home/personalcenter/mainpage",
        query: {
          id: 2,
          componentId: "Teachingexperimentclass",
        },
      });
    },
    getattachmentlist(params) {
      getattachmentlist(params)
        .then((response) => {
          this.tableData2 = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 下载
    handelDownload(row) {
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = row.url;
      link.setAttribute("download", row.name); // 文件名
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // 下载完成移除元素
    },
    // 查看
    handelCheck(row) {
      console.log("row", row);
      this.dialogVisible = true
      this.url = row.url
      this.file_type = row.ext
      this.flag = false
      this.$nextTick(() => {
        this.flag = true
      })
    },
  },
};
</script>
<style scoped lang="scss">
.mainpage {
  min-height: calc(100vh - 240px);
  background: #ffffff;
  margin-top: 30px;
  .head {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .head-title {
      font-size: 20px;
      font-weight: 400;
      color: #3D84FF;
      user-select: none;
      white-space: nowrap;
      margin-top: 10px;
        margin-left: 20px;
      // margin-bottom: 20px;
    }
    .retutnbt {
      margin-right: 40px;
      width: 64px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background: #3D84FF;
      border-radius: 4px;
      color: white;
      user-select: none;
      cursor: pointer;
      font-size: 14px;
  margin-top: 10px;
    }
  }
  .line {
    margin-top: 14px;
    margin-bottom: 4px;
    border-bottom: 2px solid #cccccc;
  }
  .maincontain {
    background: #ffffff;
    border-radius: 4px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;

    .main-contain {
      margin: 10px;
      margin-bottom: 60px;
      width: 100%;
      .title {
        font-size: 26px;
        font-weight: 400;
        color: #222222;
        margin-bottom: 10px;
      }
      .title2 {
        font-size: 20px;
        font-weight: 400;
        color: #222222;
        margin-bottom: 10px;
      }
      .contain {
        width: 100%;
        height: 100%;
        .Table {
          width: 100%;

          .item-left {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .title {
              margin-left: 10px;
              font-size: 16px;
              font-weight: 400;
              color: #222222;
            }
          }

          .item-middle {
            .title {
              font-size: 16px;
              font-weight: 400;
              color: #222222;
            }
          }

          .item-right {
            display: flex;
            justify-content: center;
            align-items: center;
            .bt {
              font-size: 16px;
              font-weight: 400;
              color: #3D84FF;
              user-select: none;
              cursor: pointer;
            }
            .line2 {
              margin-right: 8px;
              margin-left: 8px;
              height: 14px;
              border-right: 2px solid #cccccc;
            }
          }
        }
      }
    }
  }
}
</style>
